import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import Seo from '../components/seo'
import React, { useLayoutEffect } from 'react'
import Carousel from '../components/carousel'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from 'react-share'
import DynamicZone from '../helpers/dynamicZone'

const NoveltiesPage = (props) => {
  const { pageContext, data } = props
  const { navbar } = pageContext
  const { strapiNovelties: novelties } = data
  const { components } = novelties

  let SEO = ''

  if (novelties.pageMetadata !== null) {
    const { description, keywords, pageTitle } = novelties.pageMetadata

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  }

  let shareUrl = ''
  if (typeof window !== 'undefined') {
    shareUrl = window.location.href
  }
  useLayoutEffect(() => {
    const body = document.getElementsByTagName('body')
    body[0].setAttribute('class', 'fix-internalpages-without-header')

    return () => {
      body[0].removeAttribute('class', 'fix-internalpages-without-header')
    }
  })

  return (
    <Layout page={navbar}>
      {SEO}
      <section>
        {novelties.header?.visible && (
          <div className="d-flex flex-column header-carousel">
            <Carousel carousel={novelties.header} keyName={'internalPage-'} />
          </div>
        )}
        <div className="container pt-5 novelties-component">
          <p className="font-primary">
            Publicado el {moment(novelties.date).format('DD-MM-YYYY')} en{' '} 
            <b>
              {novelties.novelties_categories.map((category, i) => { 
                if ((novelties.novelties_categories.length - 1) > i) {
                  return category?.name + " - "
                } else {
                  return category?.name
                }
              })}
            </b>
          </p>
          <h1 className="mb-4">{novelties.title}</h1>
          <div className="mb-5">
            <p className="font-italic font-primary novelties-component__text pb-4">
              {novelties.summary}
            </p>
            <ReactMarkdown
              source={novelties.content}
              escapeHtml={false}
              className="format-markdown-container markdown-format fade-in-image"
            />
            <div className="col-12 d-flex justify-content-end mt-5">
              <FacebookShareButton
                url={shareUrl}
                quote={novelties.title}
                className="mr-1"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                title={novelties.title}
                className="mr-1"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl} className="mr-1">
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <DynamicZone components={components} />
        </div>
      </section>
    </Layout>
  )
}

export default NoveltiesPage

NoveltiesPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
}

export const noveltiesPageQuery = graphql`
  query($noveltiesId: Int) {
    strapiNovelties(strapiId: { eq: $noveltiesId }) {
      pageMetadata {
        description: pageDescription
        keywords: pageKeywords
        pageTitle
      }
      strapiId
      slug
      title
      summary
      content
      date(formatString: "LL", locale: "es-ar")
      novelties_categories {
        name
      }
      header {
        visible
        slide {
          content
          name
          url
          visible
          imageFilter
          imageDescription
          textButton
          backgroundImage {
            name
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
      components {
        title
        visible
        latest
        showView
        strapi_component
        carouselCard {
          color
          description
          labelBlack
        }
        icons {
          alt
          content
          icon {
            code
            type
            name
          }
        }
        videoBack {
          id
          description
          videoUrl
          buttonVideo {
            url
            content
            page {
              slug
            }
          }
          backgroundImage {
            url
          }
          video {
            url
          }
        }
        singleImage {
          id
          title
          visible
          image {
            url
          }
        }
        textContent {
          id
          content
          title
          variant
          backgroundImage{
            url
          }
          visible
        }
        video {
          id
          visible
          title
          description
          videoId
          mediaVideo {
            url
          }
        }
        map {
          id
          code
          title
          visible
        }
        MapContent {
          id
          code
          title
          visible
          mapContentText {
            id
            address
            contact
            openingHours
            image {
              url
            }
          }
        }
        pdf {
          id
          code
          title
          visible
        }
        audioList {
          id
          code
          title
          visible
        }
        calendar {
          calendarUrl
          id
          code {
            code
            title
            visible
          }
        }
        brick {
          activateVideo
          content
          id
          imageName
          videoId
          visible
          image {
            url
          }
        }
        profesionals {
          id
          rol
          visible
          professional {
            id
            name
            description
            profilePictureName
            profilePicture {
              url
              name
            }
          }
        }
        eventualitiesList {
          id
          description
          destacado
          name
          title
          visible
          image {
            name
            url
          }
        }
        tabs {
          id
          title
          content
          itemsAccordion {
            id
            title
            content
          }
        }
        transitionContent {
          text
          imagePosition
          image {
            url
          }
          color
        }
        heroContent {
          id
          title
          summary
          image {
            url
          }
          imageDark {
            url
          }
          dynamicButton {
            icon {
              code
              type
            }
            customButton {
              shape
              size
              variant
            }
            button {
              url
              label
              career_level {
                name
              }
              diary {
                slug
              }
              label
              novelty {
                slug
              }
              organizational_unit {
                slug
              }
              page {
                slug
              }
              ucc_career {
                slug
              }
            }
          }
        }
        novelties {
          id
          noveltyName
          novelty {
            id
            summary
            title
            url
            slug
            thumbnailName
            thumbnail {
              url
            }
            published_at(formatString: "DD MMMM, YYYY", locale: "es")
          }
        }
        schedule {
          id
          diaryName
          diary {
            id
            destacado
            title
            date
            time
            diary_category
            slug
            place
          }
        }
        BannerListContent {
          id
          title
          Card {
            id
            title
            description
            url
            icon {
              url
            }
            page {
              name
              slug
            }
            organizational_unit {
              name
              slug
            }
          }
        }
        quoteContent {
          title
          description
          id
          variant
          videoUrl
          image {
            name
            url
          }
          dynamicButton {
            icon {
              code
              type
            }
            customButton {
              shape
              size
              variant
            }
            button {
              url
              label
              career_level {
                name
              }
              diary {
                slug
              }
              label
              novelty {
                slug
              }
              organizational_unit {
                slug
              }
              page {
                slug
              }
              ucc_career {
                slug
              }
            }
          }
          professional {
            name
            profilePicture {
              url
            }
          }
        }
        diagonalContent {
          id
          title
          variant
          image {
            url
          }
          imageDark {
            url
          }
          dynamicButton {
            icon {
              code
              type
            }
            customButton {
              shape
              size
              variant
            }
            button {
              url
              label
              career_level {
                name
              }
              diary {
                slug
              }
              label
              novelty {
                slug
              }
              organizational_unit {
                slug
              }
              page {
                slug
              }
              ucc_career {
                slug
              }
            }
          }
        }
        logoSection {
          title
          summary
          media {
            name
            img {
              url
            }
            imageDark {
              url
            }
          }
        }
        cards {
          cardName
          content
          destacado
          id
          type
          url
          visible
          color
          labelBlack
          backgroundImageName
          backgroundImage {
            url
            name
          }
          page {
            slug
          }
          career_level {
            name
          }
          organizational_unit {
            slug
          }
        }
        slide {
          id
          name
          content
          imageDescription
          textButton
          url
          visible
          imageFilter
          backgroundImage {
            name
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
        socialNetworks {
          title
          visible
          facebook {
            code
            visible
          }
          instagram {
            code
            visible
          }
          twitter {
            code
            visible
          }
        }
        form {
          id
        }
        interest_receiver {
          option
          receiver
        }
        interest_type {
          option
          type
        } 
        label
        icon {
          code
          type
        }
        button {
          career_level {
            name
          }
          diary {
            slug
          }
          label
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
          url
        }
        customButton {
          shape
          size
          variant
        }
      }
    }
  }
`
